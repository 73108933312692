.container {
    opacity: 1;
    padding: 24px 16px 0;
}

.withBanner {
    opacity: 0.5;
}

.headerBlock {
    display: flex;
    justify-content: space-between;
    max-width: 343px;
    margin: 0 auto;
}

.contentWrapper {
    display: flex;
}

.logo {
    min-width: 40px;
}

.textBlock {
    margin-left: 8px;
}

.ratingBlock {
    display: flex;
    align-items: center;
}

.stars {
    margin: 0 4px;
}

.buttonWrapper {
    margin-top: 6px;
    margin-left: 4px;
}

.button {
    min-height: 28px !important;
    height: 28px !important;
    border-radius: 100px !important;
    padding: 6px 12px !important;
    word-break: keep-all !important;
}

.button p {
    font-size: 12px;
}

.line {
    height: 1px;
    background-color: var(--border-subdued);
    width: 100%;
    margin-bottom: 12px;
}

.banner {
    background-color: var(--white-color);
    position: absolute;
    z-index: var(--z-index-modal);
    border-radius: 12px;
    padding: 24px 16px 0;
    width: 343px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.buttonsContainer {
    display: flex;
    justify-content: space-between;
    height: 49px;
    border-top: 1px solid var(--border-subdued);
}

.bannerButton {
    padding: 12px;
    width: 100%;
}

.border {
    height: 48px;
    width: 1px;
    background-color: var(--border-subdued);
}
