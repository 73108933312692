.slickArrow {
    transform: none;
    z-index: var(--z-index-box);
    bottom: -39px;
    position: absolute;
    background-color: transparent;
    height: 16px;
    width: 16px;
}

.slickPrev {
    left: 24px;
}

.slickNext {
    right: 24px;
}
