.getAppBlock {
}

.logoBlock {
    display: flex;
    margin-bottom: 14px;
}

.logoTextBlock {
    margin-left: 16px;
}

.headerRatingsBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
}

.starsRating {
    display: flex;
    align-items: center;
}

.stars {
    margin-left: 8px;
}

.getApp p {
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrows {
    margin-left: 8px;
}

.availableBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
}

.smartphone {
    margin-right: 8px;
}
