.whiteBackground {
    background-color: var(--white-color) !important;
    min-height: calc(100vh - var(--header-height));
}

.whiteBackgroundWithBar {
    background-color: var(--white-color) !important;
    min-height: calc(100vh - 68px);
}

.whiteBackgroundWithoutHeader {
    background-color: var(--white-color) !important;
    min-height: 100vh;
}

.mb-4 {
    margin-bottom: 4px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-34 {
    margin-bottom: 34px;
}

.mb-36 {
    margin-bottom: 36px;
}

.mb-48 {
    margin-bottom: 48px;
}

.mb-56 {
    margin-bottom: 56px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-28 {
    margin-bottom: 28px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-120 {
    margin-bottom: 120px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-12 {
    margin-top: 12px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-32 {
    margin-top: 32px;
}

.mt-48 {
    margin-top: 48px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-96 {
    margin-top: 96px;
}

.my-8 {
    margin-bottom: 8px;
    margin-top: 8px;
}

.my-16 {
    margin-bottom: 16px;
    margin-top: 16px;
}

.my-24 {
    margin-bottom: 24px;
    margin-top: 24px;
}

.my-32 {
    margin-bottom: 32px;
    margin-top: 32px;
}

.my-56 {
    margin-bottom: 56px;
    margin-top: 56px;
}

.mx-4 {
    margin-left: 4px;
    margin-right: 4px;
}

.mx-16 {
    margin-left: 16px;
    margin-right: 16px;
}

.mx-24 {
    margin-left: 24px;
    margin-right: 24px;
}

.mx-32 {
    margin-left: 32px;
    margin-right: 32px;
}

.pl-32 {
    padding-left: 32px;
}

.mr-8 {
    margin-right: 8px;
}

.mr-18 {
    margin-right: 18px;
}

.ml-36 {
    margin-left: 36px;
}

.colorBrand {
    color: var(--brand-primary);
}

.colorBlack {
    color: var(--black-color) !important;
}

.colorWhite {
    color: var(--white-color) !important;
}

.centered {
    text-align: center;
}

.uppercase {
    text-transform: uppercase;
}

.underline {
    text-decoration: underline;
}

.lineThrough {
    text-decoration: line-through;
}

.bold {
    font-weight: 700;
}

.colorPromo {
    color: var(--brand-primary) !important;
}

.colorSafe {
    color: var(--safe) !important;
}

.colorTextSecondary {
    color: var(--text-secondary) !important;
}

.colorWhite {
    color: var(--white-color);
}

.colorBorderDefault {
    color: var(--border-default) !important;
}

.firstLetterUppercase:first-letter {
    text-transform: capitalize;
}

.fixedPolicy {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(
        180deg,
        rgba(29, 29, 29, 0) 0%,
        rgba(29, 29, 29, 0.11) 6.25%,
        rgba(29, 29, 29, 0.21) 11.98%,
        #1d1d1d 38.54%
    );
    height: 52px;
    padding-bottom: 12px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
