.sliderWrapper {
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + var(--default-indent-double));
    margin-bottom: 72px;
}

.image {
    width: auto;
    height: 100%;
    max-height: 406px;
    margin: 0 auto;
}

.blueDot {
    height: 8px;
    width: 8px;
    background-color: var(--white-color);
    opacity: 0.3;
    border-radius: 50%;
    display: inline-block;
}
