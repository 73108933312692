.container {
    position: relative;
    padding: 24px var(--default-indent) 0;
    min-height: calc(100vh - var(--header-height));
    background-color: var(--background-color);
    background-repeat: repeat;
}

.optionWrapper {
    margin-bottom: 50px;
}

.rateBlock {
    margin: 15px auto 0;
}

.downloads {
    margin-top: 3px;
}

.rate {
    display: flex;
    justify-content: center;
    margin-bottom: 13px;
}

.rate > *:first-child {
    margin-top: 3px;
    margin-right: 8px;
}

.rate > *:nth-child(2n) {
    margin-right: 7px;
    margin-top: -2px;
}

.LinkWrapper {
    display: flex;
    justify-content: center;
    margin: 0 auto 10px;
    background-color: transparent;
    border: none;
}
