.bigRating {
    font-size: 48px !important;
    line-height: 56px !important;
}

.ratingsBlock {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
}

.rating {
    width: 94px;
    height: 96px;
    background-color: var(--surface-default);
    padding: 8px 12px;
    border-radius: 8px;
}

.ratingsCount {
    text-align: right;
}

.reviewItem {
    position: relative;
    padding-left: 20px;
    margin-bottom: 44px;
}

.reviewItem:nth-child(3n) {
    margin-bottom: 0;
}

.quotes {
    position: absolute;
    left: 0;
}

.quotes path {
    fill: var(--border-subdued);
}

.authorReview {
    display: flex;
    align-items: center;
    margin-left: 2px;
}

.starsIcon {
    margin-right: 20px;
}
