.appInfo,
.privacyFooter {
    display: flex;
    justify-content: space-between;
}

.line {
    height: 1px;
    width: 100%;
    margin: 12px auto 12px;
    background-color: var(--border-default);
}

.infoValue {
    text-align: right;
    max-width: 243px;
}
