.buttonBox {
    position: absolute;
    bottom: 0;
    height: 36px;
    width: 100%;
    background-color: var(--brand-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--background-color);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.buttonText {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageWrapper {
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    padding-top: 3px;
}

.smallImage {
    max-height: 105px;
}

.nextIcon {
    margin-left: 6px;
}
