.optionBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 12px;
}

.option {
    width: 100%;
    max-width: calc(50% - 6px);
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    margin-bottom: 12px;
    background: var(--card-color);
    padding: 2px 0 0;
    height: 140px;
}

.option:before {
    content: '';
    position: absolute;
    bottom: -3px;
    z-index: -4;
    width: 100%;
    padding-top: 100%;
    background: var(--surface-default);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-sizing: border-box;
}

.buttonText {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 769px) {
    .option {
        width: 165px;
    }

    .option:not(:last-child) {
        margin-right: 20px;
    }

    .option:nth-child(-n + 2) {
        margin-bottom: 24px;
    }
}
