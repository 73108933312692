.line {
    width: 100%;
    height: 1px;
    background-color: var(--border-default);
    margin: 24px auto 24px;
}

.firstDisclaimer {
    padding-right: 1px;
}
